import { Component, Vue, Prop } from 'vue-property-decorator'

import { FieldSearchModel } from '@/molds/FieldSearchModel';

import ModalSeach from './modalSeach/ModalSeach.vue';

import { 
  wIcon,
  WButton
 } from '@/components/wrappers';

@Component({
	components: {
    wIcon,
    WButton
  }
})

export default class FieldSearch extends Vue {
  @Prop({ required: true} ) config!: FieldSearchModel;
  
  @Prop() value!: string;
  @Prop() disabled!: boolean;
  @Prop() isInvalid!: boolean;

  get content(){
    return this.value;
  }

  set content(newVal: string){
    this.$emit('input', newVal);
  }

  openModal(): void {
    this.$buefy.modal.open({
      parent: this,
      component: ModalSeach,
      customClass: '',
      width: '470',
      props: {
        modalTitle: this.config.label,
				endpoint: this.config.endpoint,
        urlQueryKey: this.config.urlQueryKey,
        showColum: this.config.showColum,
				fieldSearchModel: this.config
      },
      events: {
        selected: (item: any) => {
          if(this.config.inputField != undefined){
            this.content = item[this.config.inputField];
          }
          this.$emit('selected', item)
        }
      }
    })
  }
  
}
