import { Component, Vue, Prop } from 'vue-property-decorator'

//models
import { ListPanelModel } from '@/molds/ListPanelModel'

@Component({
	
})
export default class ListPanel extends Vue {
  @Prop() listPanel!: ListPanelModel;
  @Prop() data!: object;

  clickRow(item: object){
    this.$emit('clickRow', item)
  }

}
