import { Component, Vue, Prop } from 'vue-property-decorator';

//request
import { api } from '@/services/api'

//models
import { ListPanelModel } from '@/molds/ListPanelModel';
import { FieldSearchModel } from '@/molds/FieldSearchModel';

//components
import ListPanel from '@/components/listPanel/ListPanel.vue'

//services
import { Alert } from '@/services/notification';

import {
	WColumns,
	WColumn,
	wIcon,
	WButton,
	Loader
} from '@/components/wrappers';

@Component({
	components: {
		WColumns,
		WColumn,
		ListPanel,
		wIcon,
		WButton,
		Loader
	}
})

export default class ModalSeach extends Vue {
	// props
	@Prop() url!: string;
	@Prop() endpoint!: string;
	@Prop() showColum!: string;
	@Prop() modalTitle!: string;
	@Prop() urlQueryKey!: string;
	@Prop() fieldSearchModel!: FieldSearchModel;

	alert = new Alert(this.$store)

	// data
	inputSearch = '';
	listResult: Array<object> = []
	timeout = 0;
	loading = false;

	// components data
	listPanel: ListPanelModel = {
		field: this.showColum
	}

	mounted() {
		//this.getData('');
	}

	// methods
	keydownInputSearch(): void {
		if (this.timeout > 0) {
			clearTimeout(this.timeout);
		}
		this.timeout = setTimeout(this.clickBtnSearch, 500);
	}

	clickBtnSearch(): void {
		this.getData(this.inputSearch);
	}

	clickRowListPanel(item: any) {
		this.$emit('selected', item);
		if(this.fieldSearchModel?.closeAfter){
			this.$emit('close');		
		}
	}

	async getData(value: string): Promise<void> {
		if(value.trim() != ""){
			let err = false
			this.loading = true;
			await api.get(`${this.endpoint}?${this.urlQueryKey}=${value}`).then(
				response => {
					if (response.data.list) {
						this.setListResult(response.data.list)
					} else {
						this.alert.danger("Desculpe, ocorreu um erro inesperado. :(", "5000")
					}
				},
				error => {
					err = error
				}
			)
			if(err){
				this.alert.apiMessage("Não foi possível pesquisar usuário.", err, "danger")
			}
			this.loading = false;
		}
	}

	setListResult(value: Array<object>): void {
		this.listResult = value;
	}

}
